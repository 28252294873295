import api from '@/lib/api'

export default {
  async getCatalogCategories ({ dispatch }, _) {
    try {
      let { payload } = await api.getCatalogCategoriesAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getCatalogPredefinedMessages ({ dispatch }, _) {
    try {
      let { payload } = await api.getCatalogPredefinedMessagesAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getTypeBeneficList ({ dispatch }, _) {
    try {
      let { payload } = await api.getTypeBeneficListAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
