const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getDashboardAPI () {
    const response = await fetch(`${API_URL}/admin/dashboard`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async getDashboardChartProductsAPI () {
    const response = await fetch(`${API_URL}/admin/dashboard/chart-products`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async getDashboardAPISegmentation (date) {
    const response = await fetch(`${API_URL}/admin/dashboard/segmetation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('access-token')
      },
      body: JSON.stringify(date)
    })
    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async getDashboardChartProductsAPISegmentation (date) {
    try {
      const response = await fetch(`${API_URL}/admin/dashboard/chart-products/segmetation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('access-token')
        },
        body: JSON.stringify(date)
      })
      let resp = await response.json()
      if (!response.ok) {
        if (response.status === 400) {
          throw new Error(resp.error)
        }
      }
      return resp
    } catch (error) {
      this.addTemporalNotification({ type: 'danger', message: error.message })
      return false
    }
  }
}
