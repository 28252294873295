const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async loginAdmin ({ email, password }) {
    const response = await fetch(`${API_URL}/admin/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password })
    })
    if (response.ok) {
      return response.json()
    } else if (response.status === 400) {
      return Promise.reject(new Error(`ha ocurrido un error, contacté a givu.`))
    } else {
      return Promise.reject(new Error('Request Error'))
    }
  },
  async validateSession () {
    const response = await fetch(`${API_URL}/admin/auth/validate-session`, {
      headers: {
        authorization: getToken()
      }
    })
    if (response.ok) {
      return response.json()
    } else if (response.status === 400) {
      return Promise.reject(new Error('Su sesión ha expirado'))
    } else {
      return Promise.reject(new Error('Request Error'))
    }
  },
  async requestRecoveryPasswordAPI ({ email }) {
    const response = await fetch(`${API_URL}/admin/recovery/request`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    })

    let resp = await response.json()
    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async validateTokenRecoveryAPI (token) {
    const response = await fetch(`${API_URL}/admin/recovery/validate-token`, {
      method: 'POST',
      headers: {
        authorization: token
      }
    })
    if (response.ok) {
      return response.json()
    } else if (response.status === 400) {
      return Promise.reject(new Error('Su sesión ha expirado'))
    } else {
      return Promise.reject(new Error('Request Error'))
    }
  },
  async recoveryPasswordAPI ({ password, token }) {
    const response = await fetch(`${API_URL}/admin/recovery/recovery-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify({ password })
    })
    const result = await response.json()
    if (response.ok) {
      return result
    } else if (response.status === 400) {
      return Promise.reject(new Error(result.error))
    } else {
      return Promise.reject(new Error('Request Error'))
    }
  }
}
