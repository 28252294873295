const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getTransactionsAPI (page = 1, search = '', filter = '', limit = 10) {
    const response = await fetch(`${API_URL}/admin/transactions?page=${page}&limit=${limit}&search=${search}&filter=${filter}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async csvDownloadTransactionsAPI (search = '', filter = '') {
    const response = await fetch(`${API_URL}/admin/transactions/getcsv?search=${search}&filter=${filter}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  }
}
