export default {
  title: null,
  decription: null,
  price: null,
  condition: '-1',
  categoriesSelected: [],
  characteristicsSelected: [],
  addressNewLocation: null,
  addressSelected: [],
  otherMehod: false,
  personalDelivery: false,
  images: [],
  stock: null,

  step: 1
}
