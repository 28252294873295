import auth from './authetication'
import ui from './ui'
import adminUsers from './adminUsers'
import users from './users'
import products from './products'
import categories from './categories'
import sales from './sales'
import comments from './comments'
import promotions from './promotions'
import catalogs from './catalog'
import messages from './messages'
import transactions from './transactions'
import dashboard from './dashboard'
import characteristics from './characteristics'
import bankMovements from './bankMovements'
import claims from './claims'
import userGroups from './userGroups'
import notifications from './notifications'
import reports from './reports'
import blog from './blog'
import newProduct from './newProduct'
import chats from './chats'
import press from './press'
import notificationsUsers from './notificationUsers'

export default {
  ...auth,
  ...ui,
  ...adminUsers,
  ...users,
  ...products,
  ...categories,
  ...sales,
  ...comments,
  ...promotions,
  ...catalogs,
  ...messages,
  ...transactions,
  ...dashboard,
  ...characteristics,
  ...bankMovements,
  ...claims,
  ...userGroups,
  ...notifications,
  ...reports,
  ...blog,
  ...newProduct,
  ...chats,
  ...press,
  ...notificationsUsers
}
