const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getProductsAPI (page = 1, filter = 0, search = '', limit = 10, sortKey = 'price', sortOrder = 'desc', username = '', startDate = '', endDate = '', onlyGiver = false) {
    const url = `${API_URL}/v5/admin/products?page=${page}&filter=${filter}&search=${search}&limit=${limit}&sortKey=${sortKey}&sortOrder=${sortOrder}&username=${username}&startDate=${startDate}&endDate=${endDate}&onlyGiver=${onlyGiver}`
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken()
        }
      })
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const data = await response.json()
      return data
    } catch (error) {
      console.error('Error fetching products:', error)
      throw error
    }
  },
  async getProductDetailAPI (productId) {
    const response = await fetch(`${API_URL}/v5/admin/products/${productId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })
    let resp = await response.json()
    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async editProductAPI (productId, product) {
    const response = await fetch(`${API_URL}/admin/products/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      },
      body: JSON.stringify(product)
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async deleteImageProductAPI (productId, body) {
    const response = await fetch(`${API_URL}/admin/products/image/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: JSON.stringify(body)
    })
    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async uploadImageProductAPI (productId, image, main) {
    const formData = new FormData()
    formData.append('image', image.file)
    formData.append('main', main)
    const response = await fetch(`${API_URL}/admin/products/image/${productId}/add`, {
      method: 'PUT',
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: formData
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async changeStatusProductAPI (productId, body) {
    const { status } = body
    const response = await fetch(`${API_URL}/v5/admin/products/status/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: JSON.stringify(status)
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async responseCommentProductAPI (idComment, userId, productId, responseComment) {
    const body = {
      userId: userId,
      productId: productId,
      response: responseComment
    }
    const response = await fetch(`${API_URL}/admin/products/comment/${idComment}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: JSON.stringify(body)
    })

    const resp = await response.json()

    if (!response.ok) {
      throw new Error(resp.error || 'Error en la respuesta del servidor')
    }
    return resp
  },

  async changeCoverAPI (productId, body) {
    const response = await fetch(`${API_URL}/admin/products/cover/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: JSON.stringify(body)
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async deleteProductByIdAPI (productId) {
    const response = await fetch(`${API_URL}/admin/products/delete/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })
    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async removeProductByIdAPI (productId) {
    const response = await fetch(`${API_URL}/v5/admin/products/remove-permanently/${productId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })
    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async csvDownloadProductsAPI (filter = 0, search = '', startDate = '', endDate = '', onlyGiver = '') {
    const response = await fetch(`${API_URL}/v5/admin/products/getcsv?filter=${filter}&search=${search}&startDate=${startDate}&endDate=${endDate}&onlyGiver=${onlyGiver}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async updateProductVisibility (productId) {
    const response = await fetch(`${API_URL}/admin/products/visibility/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })
    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async updateProductSold (productId) {
    const response = await fetch(`${API_URL}/v5/admin/products/sold/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })
    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async getSaleProductApi (productId) {
    const response = await fetch(`${API_URL}/admin/products/${productId}/sale-detail`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async getNotificationProductAPI (productId) {
    const response = await fetch(`${API_URL}/admin/products/${productId}/message-admin`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async getDiagnosticAPI (productId) {
    const response = await fetch(`${API_URL}/v5/admin/products/diagnostic/${productId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async addPruduct ({
    title,
    description,
    brand,
    model,
    box,
    accessories,
    freeShipping,
    placeDeposit,
    price,
    condition,
    categoriesSelected,
    characteristicsSelected,
    addressNewLocation,
    addressSelected,
    otherMehod,
    personalDelivery,
    images,
    stock
  }) {
    let newData = new FormData()

    images.map((image, i) => {
      if (i === 0) return newData.append('main', image.file)
      return newData.append(`image${i}`, image.file)
    })
    newData.append('name', title)
    newData.append('description', description)
    newData.append('brand', brand)
    newData.append('model', model)
    newData.append('box', box)
    newData.append('accessories', accessories)
    newData.append('free_shipping', freeShipping)
    newData.append('place_deposit', placeDeposit)
    newData.append('price', price)
    newData.append('condition', condition)
    newData.append('stock', stock)
    newData.append('categories', JSON.stringify(categoriesSelected))
    newData.append('characteristics', JSON.stringify(characteristicsSelected))
    newData.append('address', JSON.stringify(addressSelected))
    newData.append('addressNewLocation', JSON.stringify(addressNewLocation))
    newData.append('otherMehod', JSON.stringify(otherMehod))
    newData.append('personalDelivery', JSON.stringify(personalDelivery))

    const response = await fetch(`${API_URL}/admin/product/add`, {
      method: 'POST',
      headers: {
        'Authorization': getToken()
      },
      body: newData
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async updateProductPreapprovedAPI (productId) {
    const response = await fetch(`${API_URL}/v5/admin/products/status/preapproved/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })
    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async updateAditionalInformationAPI (productId, body) {
    const response = await fetch(`${API_URL}/v5/admin/products/aditional-information/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      },
      body: JSON.stringify(body)
    })
    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async updateDegreeAPI (productId, degree) {
    const body = {
      degree: degree
    }
    const response = await fetch(`${API_URL}/v5/admin/products/degree/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      },
      body: JSON.stringify(body)
    })
    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async updateProductProposalAPI (productId, amount) {
    const body = {
      amount: amount
    }
    const response = await fetch(`${API_URL}/v5/admin/products/status/proposal/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      },
      body: JSON.stringify(body)
    })
    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async changeStatusProductReceiptAPI (productId) {
    const response = await fetch(`${API_URL}/v5/admin/products/status/receipt/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async changeStatusPaymentProductAPI (productId) {
    const response = await fetch(`${API_URL}/v5/admin/products/status/payment/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async changeStatusProductPaymentImageAPI (productId, fileImage) {
    const response = await fetch(`${API_URL}/v5/admin/products/status/payment-image/${productId}`, {
      method: 'PUT',
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: fileImage
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async getReceiptImageAPI (productId) {
    const response = await fetch(`${API_URL}/v5/admin/products/receipt/image/${productId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })
    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async getPropertySessionAPI (productId) {
    const response = await fetch(`${API_URL}/v5/admin/products/property-session/${productId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async getPaymentImageAPI (productId) {
    const response = await fetch(`${API_URL}/v5/admin/products/payment-image/${productId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async changeStatusFinalizeProductAPI (productId) {
    const response = await fetch(`${API_URL}/v5/admin/products/status/finalize/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  }
}
