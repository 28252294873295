import api from '@/lib/api'

export default {
  async getCategories ({ dispatch }, { page = 1, search }) {
    try {
      let { payload, totalPages } = await api.getCategoriesAPI(page, search)
      return { payload, totalPages }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async createCategory ({ dispatch }, { name, parentId }) {
    try {
      let { success, message = null } = await api.createCategoryAPI(name, parentId)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async updateCategory ({ dispatch }, { id, name, parentId }) {
    try {
      let { success, message = null } = await api.updateCategoryAPI(name, parentId, id)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async csvDownloadCategories () {
    try {
      let { payload } = await api.csvDownloadCategoriesAPI()
      return payload
    } catch (error) {
      return false
    }
  }
}
