export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    redirect: 'dashboard',
    meta: { auth: true },
    children: [
      {
        path: '/',
        name: 'first-view',
        component: () => import('@/views/Dashboard.vue')
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue')
      },
      {
        path: 'productos',
        name: 'productos',
        component: () => import('@/views/productos/Products.vue')
      },
      {
        path: 'productos/detalle/:productId',
        name: 'detalle-producto',
        component: () => import('@/views/productos/ProductDetail.vue')
      },
      {
        path: 'nuevo-producto',
        name: 'producto',
        component: () => import('@/views/nuevoProducto/Product.vue')
      },
      {
        path: 'categorias',
        name: 'categorias',
        component: () => import('@/views/categorias/Categories.vue')
      },
      {
        path: 'caracteristicas',
        name: 'caracteristicas',
        component: () => import('@/views/Caracteristicas/Index.vue')
      },
      {
        path: 'reclamos',
        name: 'reclamos',
        component: () => import('@/views/reclamos/Claims.vue')
      },
      {
        path: 'reclamos/detalle/:claimId',
        name: 'reclamo-detalle',
        component: () => import('@/views/reclamos/ClaimsDetail.vue')
      },
      {
        path: 'ventas',
        name: 'ventas',
        component: () => import('@/views/ventas/Sales.vue')
      },
      {
        path: 'ventas/detalle/:saleId',
        name: 'detalle-venta',
        component: () => import('@/views/ventas/SalesDetail.vue')
      },
      {
        path: 'transacciones',
        name: 'transacciones',
        component: () => import('@/views/transacciones/Transactions.vue')
      },
      {
        path: 'banco',
        name: 'banco',
        component: () => import('@/views/Bank/Index.vue')
      },
      {
        path: 'usuarios',
        name: 'usuarios',
        component: () => import('@/views/usuarios/Users.vue')
      },
      {
        path: 'leads',
        name: 'leads',
        component: () => import('@/views/leads/Leads.vue')
      },
      {
        path: 'usuarios/detalle/:userId',
        name: 'detalle-usuario',
        component: () => import('@/views/usuarios/UsersDetail.vue')
      },
      {
        path: 'comentarios',
        name: 'comentarios',
        component: () => import('@/views/comentarios/Comments.vue')
      },
      {
        path: 'notificaciones',
        name: 'notificaciones',
        component: () => import('@/views/notificaciones/Notifications.vue')
      },
      {
        path: 'administradores',
        name: 'administradores',
        component: () => import('@/views/administradores/Administrators.vue')
      },
      // {
      //   path: 'promociones',
      //   name: 'promociones',
      //   component: () => import('@/views/promociones/Promotions.vue')
      // },
      {
        path: 'reportes',
        name: 'reportes',
        component: () => import('@/views/Reportes/Index.vue')
      },
      {
        path: '/blog',
        component: () => import('@/views/blog/MainBlog.vue'),
        children: [
          {
            path: '/',
            name: 'blog',
            component: () => import('@/views/blog/Blog.vue')
          },
          {
            path: 'agregar',
            name: 'agregar-blog',
            component: () => import('@/views/blog/AddBlog.vue')
          },
          {
            path: 'editar/:postId',
            name: 'editar-blog',
            component: () => import('@/views/blog/EditBlog.vue')
          },
          {
            path: 'blog/categorias',
            name: 'blog-categorias',
            component: () => import('@/views/blog/Categories.vue')
          },
          {
            path: 'comentarios',
            name: 'comentarios-blog',
            component: () => import('@/views/blog/BlogComments.vue')
          }
        ]
      },
      {
        path: '/prensa',
        component: () => import('@/views/press/MainPress.vue'),
        children: [
          {
            path: '/',
            name: 'prensa',
            component: () => import('@/views/press/Press.vue')
          }
        ]
      },
      {
        path: 'chats-list',
        name: 'chats-list',
        component: () => import('@/views/ChatsList.vue')
      },
      {
        path: 'inventario',
        name: 'inventario',
        component: () => import('@/views/inventory/Inventory.vue')
      },
      {
        path: 'inventario/detalle/:productId',
        name: 'detalle-inventario',
        component: () => import('@/views/inventory/InventoryDetail.vue')
      }
    ]
  }
]
