import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store/index.js'
import VeeValidate, { Validator } from 'vee-validate'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import es from 'vee-validate/dist/locale/es'
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'

import * as VueGoogleMaps from 'vue2-google-maps'
import { createProvider } from './vue-apollo'

Vue.config.productionTip = false
Vue.component('v-date-picker', DatePicker)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbGy_jgxDN8PJYct2jK1LgzETKhaRMKbk',
    libraries: 'places' // necessary for places input
  }
})
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')

Vue.use(VeeValidate, {
  errorBagName: 'vverrors',
  fieldsBagName: 'vvfields',
  events: 'change|blur'
})
Validator.localize('es', es)

Vue.component('v-select', vSelect)
