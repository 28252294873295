const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getPressListAPI (page = 1, size = 10) {
    const response = await fetch(`${API_URL}/admin/prensa?page=${page}&size=${size}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })

    const resp = await response.json()
    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async getPressDetailAPI (pressId) {
    const response = await fetch(`${API_URL}/admin/prensa/${pressId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })

    const resp = await response.json()
    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async changePressStatusAPI (pressId) {
    const response = await fetch(`${API_URL}/admin/prensa/${pressId}/status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })

    const resp = await response.json()
    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async addPressAPI (pressData) {
    const { image, ...body } = pressData
    const formData = new FormData()
    if (image) formData.append('image', image.file)
    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value)
    })

    const response = await fetch(`${API_URL}/admin/prensa`, {
      method: 'POST',
      headers: { Authorization: getToken() },
      body: formData
    })

    const resp = await response.json()
    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async editPressAPI (pressData, pressId) {
    const { image, ...body } = pressData
    const formData = new FormData()
    if (image) formData.append('image', image.file)
    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value)
    })

    const response = await fetch(`${API_URL}/admin/prensa/${pressId}`, {
      method: 'PUT',
      headers: {
        Authorization: getToken()
      },
      body: formData
    })

    const resp = await response.json()
    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  }
}
