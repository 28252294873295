import api from '@/lib/api'

export default {
  async getsalesAPI ({ dispatch }, { page = 1, search, filter }) {
    try {
      let { payload, totalPages } = await api.getsalesAPI(page, search, filter)
      return { payload, totalPages }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getSaleById ({ dispatch }, saleId) {
    try {
      const response = await api.getSaleByIdAPI(saleId)
      return response
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async csvDownloadSales ({ dispatch }, { search, filter }) {
    try {
      let { payload } = await api.csvDownloadSalesAPI(search, filter)
      return payload
    } catch (error) {
      return false
    }
  },

  async updateSaleStatus ({ dispatch }, { saleId, newStatus }) {
    try {
      const response = await api.updateSaleStatusAPI(saleId, newStatus)
      return response
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
