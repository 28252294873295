const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getAdminNotifications (page, search) {
    const response = await fetch(`${API_URL}/admin/notifications?page=${page}`, {
      method: 'GET',
      headers: {
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async openNotification (notificationId) {
    const response = await fetch(`${API_URL}/admin/notification/${notificationId}`, {
      method: 'PUT',
      headers: {
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  }
}
