import api from '@/lib/api'
import router from '@/router'

export default {
  async signin ({ commit, dispatch, state }, dataAdmin) {
    try {
      let { token, success, message = null } = await api.loginAdmin(dataAdmin)
      if (!success) throw new Error(message)
      localStorage.setItem('access-token', token)
      router.replace({ name: 'first-view' })
      return success
    } catch (error) {
      dispatch('addTemporalNotification', {
        type: 'danger',
        message: 'Credenciales inválidas'
      })
      return false
    }
  },
  async validateSession ({ commit, state, dispatch }) {
    try {
      const response = await api.validateSession()
      commit('setAuthValues', { ...response })
      return { complete: true, payload: response }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return { complete: false, message: error.message }
    }
  },
  async requestRecoveryPassword ({ dispatch }, { email }) {
    try {
      let { success, message = '', error = '' } = await api.requestRecoveryPasswordAPI({ email })
      dispatch('addTemporalNotification', {
        type: success ? 'success' : 'danger',
        message: success
          ? 'Si la cuenta existe, recibirás un correo para recuperar tu contraseña.'
          : 'Error al enviar el correo de recuperación de contraseña.'
      })
      return success ? message : error
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return error
    }
  },
  async validateTokenRecovery ({ commit, dispatch }, token) {
    try {
      const response = await api.validateTokenRecoveryAPI(token)
      commit('setRecoveryToken', response.token)

      return { success: true, payload: response }
    } catch (error) {
      dispatch('addTemporalNotification', {
        type: 'danger',
        message: error.message || 'Token inválido'
      })
      return { success: false, message: error.message }
    }
  },
  async recoveryPassword ({ commit, dispatch }, { password, token }) {
    try {
      const response = await api.recoveryPasswordAPI({ password, token })
      if (!response.success) {
        return { success: false, criteria: response.criteria }
      }
      dispatch('addTemporalNotification', {
        type: 'success',
        message: 'Contraseña actualizada correctamente'
      })
      return { success: true }
    } catch (error) {
      dispatch('addTemporalNotification', {
        type: 'danger',
        message: error.message || 'Error al actualizar la contraseña'
      })
      return { success: false, message: error.message }
    }
  }
}
