import api from '@/lib/api'

export default {
  async getPressList ({ dispatch }, { page = 1, size = 10 }) {
    try {
      const payload = await api.getPressListAPI(page, size)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getPressDetail ({ dispatch }, pressId) {
    try {
      const payload = await api.getPressDetailAPI(pressId)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async addPress ({ dispatch }, data) {
    try {
      const { success, message } = await api.addPressAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: message })

      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async editUpdatePress ({ dispatch }, { data, pressId }) {
    try {
      const { success, message } = await api.editPressAPI(data, pressId)
      dispatch('addTemporalNotification', { type: 'success', message: message })

      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async changePressStatus ({ dispatch }, pressId) {
    try {
      const { success, message } = await api.changePressStatusAPI(pressId)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
