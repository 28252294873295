import api from '@/lib/api'

export default {
  async getCharacteristics ({ dispatch }, { page = 1, search, filter }) {
    try {
      let { payload, totalPages } = await api.getCharacteristicsAPI(page, search, filter)
      return { payload, totalPages }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async createCharacteristic ({ dispatch }, { name, categoryId }) {
    try {
      let { success, message = null } = await api.createCharacteristicAPI(name, categoryId)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async updateCharacteristic ({ dispatch }, { id, name, categoryId, active }) {
    try {
      let { success, message = null } = await api.updateCharacteristicAPI(name, categoryId, active, id)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
