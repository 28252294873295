import api from '@/lib/api'

export default {
  async getExecutiveReport ({ commit, state, dispatch }, { startDate, endDate, filter, page = 1, limit = 10 }) {
    try {
      let payload = await api.getExecutiveReportAPI(startDate, endDate, filter, page, limit)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async csvDownloadReports ({ commit, state, dispatch }, { startDate, endDate, filter }) {
    try {
      let { payload } = await api.csvDownloadReportsAPI(startDate, endDate, filter)
      return payload
    } catch (error) {
      return false
    }
  }
}
