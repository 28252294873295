export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/recovery-password',
    name: 'recoveryPassword',
    component: () => import('@/views/RecoveryPassword.vue')
  }
]
