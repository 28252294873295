import api from '@/lib/api'

export default {
  async getAdminNotificationsUsers ({ dispatch }, { page = 1 }) {
    try {
      let { payload, totalPages, success } = await api.getAdminNotificationsUsersAPI(page)
      return { payload, totalPages, success }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async sendNotificationUsers ({ dispatch }, body) {
    try {
      let payload = await api.sendNotificationUsersAPI(body)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
