const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getCustomersAPI (page = 1, search = '', filterGroup = '', startDate = null, endDate = null) {
    const response = await fetch(`${API_URL}/admin/customer/users?page=${page}&search=${search}&filterGroup=${filterGroup}&startDate=${startDate}&endDate=${endDate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async getLeadsAPI (page = 1, search = '', filterGroup = '') {
    const response = await fetch(`${API_URL}/admin/leads?page=${page}&search=${search}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async updateLeadsAdminContacted (leadId, isContacted) {
    const response = await fetch(`${API_URL}/admin/leads/${leadId}/adminContacted`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      },
      body: JSON.stringify({ isContacted })
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async getCustomerByIdAPI (customerId, productStatus) {
    const response = await fetch(`${API_URL}/admin/customer/users/${customerId}?status=${productStatus}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async getCustomerProductsByIdAPI (customerId, productStatus, page) {
    const response = await fetch(`${API_URL}/admin/customer/users/${customerId}/products?page=${page}&status=${productStatus}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async getCustomerPurchasedProductsByIdAPI (customerId, page) {
    const response = await fetch(`${API_URL}/admin/customer/users/${customerId}/purchased-products?page=${page}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async userUpdateStatus (data) {
    const { userId, ...dataActive } = data
    const response = await fetch(`${API_URL}/admin/customer/${userId}/status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('access-token')
      },
      body: JSON.stringify(dataActive)
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async addGivsToUserAPI ({ userId, amount }) {
    const response = await fetch(`${API_URL}/admin/customer/${userId}/givs/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('access-token')
      },
      body: JSON.stringify({ amount })
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async csvDownloadUsersAPI (search = '', filterGroup = '', startDate = null, endDate = null) {
    const response = await fetch(`${API_URL}/admin/customer/getcsv?search=${search}&filterGroup=${filterGroup}&startDate=${startDate}&endDate=${endDate}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async csvDownloadLeadsAPI (search = '', filterGroup = '') {
    const response = await fetch(`${API_URL}/admin/lead/getcsv?search=${search}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async getChatId (userId) {
    try {
      let response = await fetch(`${API_URL}/admin/chats/${userId}`, {
        method: 'GET',
        headers: {
          'Authorization': getToken()
        }
      })
      if (response.ok) {
        return response.json()
      } else if (response.status === 400) {
        return Promise.reject(new Error(`No se ha podido obtener el listado de chats`))
      } else {
        return Promise.reject(new Error('Request Error'))
      }
    } catch (error) {
      // console.log(error)
    }
  },

  async forwardMailAPI (userId) {
    const response = await fetch(`${API_URL}/resend-email${userId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('access-token')
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  }
}
