const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getClaimsAPI (page = '', search = '') {
    const response = await fetch(`${API_URL}/admin/claims?page=${page}&search=${search}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async getClaimsMessagesAPI (claimId, page = 1) {
    const response = await fetch(`${API_URL}/admin/claims/${claimId}/messages?page=${page}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async responseClaimsAPI (claimId, message) {
    const response = await fetch(`${API_URL}/admin/claims/${claimId}/response`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      },
      body: JSON.stringify(message)
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async closeClaimsAPI (claimId, close) {
    const response = await fetch(`${API_URL}/admin/claims/${claimId}/close`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      },
      body: JSON.stringify(close)
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async csvDownloadClaims () {
    const response = await fetch(`${API_URL}/admin/claims/getcsv`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  }
}
