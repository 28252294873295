<template>
  <div id="app">
    <the-notification/>
    <router-view/>
  </div>
</template>

<script>
import TheNotification from '@/components/ui/TheNotifications'
export default {
  name: 'App',
  components: { TheNotification },
  mounted () {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1024 && this.$store.state.ui.sidebar) {
        this.$store.commit('toggleSidebar')
      } else if (window.innerWidth >= 1024 && !this.$store.state.ui.sidebar) {
        this.$store.commit('toggleSidebar')
      }
    })
  }
}
</script>

<style lang="sass">
  $body-background-color: whitesmoke

  @import "bulma/bulma.sass"
  @import "bulma-switch/src/sass/index.sass"
  @import "~bulma-timeline"
  @import "bulma-tooltip/src/sass/index.sass"
  @import "~bulma-steps"
</style>

<style lang="scss">
  :root {
    --error-color: #ff3860;
    --primary-color: #DA6255;
    --primary-color-light: #658abf;
    --primary-color-dark: #de752c;
    --secondary-color: #292A30;
  }

  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto!important;
  }

  #app {
    height: 100%;
    width: 100%;
    position: relative;
  }

  #app > .hero {
    flex: 1;
  }
 @media screen and (min-width: 768px) {
    #app {
      display: flex;
    }
  }
  .modal-card-head {
    background-color: var(--secondary-color);
    color: #FFF;
  }
  .modal-card-title {
    color: #FFFFFF;
  }
  .modal-card-foot {
    justify-content: flex-end;
  }
  .level {
    margin-bottom: 5px !important;
  }
  .level h1 {
    padding-bottom: 5px;
  }
  .button-givu {
    background-color: var(--secondary-color);
    border: var(--secondary-color);
    color: #FFFFFF;
  }
  .button-givu:hover, .button-givu:active, .button-givu:focus {
    opacity: 0.9;
    border: var(--secondary-color);
    color: #FFFFFF;
  }
  .titleCard {
    color: #5F5F5F;
    padding-left: 10px !important;
    font-weight: 600;
    font-size: 18px;
  }
  h1 {
    font-size: 24px !important;
  }
  .box {
    -webkit-box-shadow: 0 2px 3px rgba(117, 116, 116, 0.1), 0 0 0 1px rgba(117, 116, 116, 0.1);
    box-shadow: 0 2px 3px rgba(117, 116, 116, 0.1), 0 0 0 1px rgba(117, 116, 116, 0.1);
  }
  .separator {
    border-bottom: 1px solid #F0F0F0;
    padding-top:5px;
    padding-bottom:5px;
  }
  .is-current {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
  }

  .table a {
    cursor: pointer;
    color: var(--secondary-color);
  }
  .table a:hover {
    cursor: pointer;
    color: var(--primary-color);
  }
  .table th {
    text-align: center;
    background-color: #292A30;
    color: #f5fcff !important;
    border: #EE7C34;
    padding: 5px 0;
    font-size: 14px;
  }

  .table th:first {
    border-radius: 5px 0 0 0;
  }
  .table th:last-child {
    border-radius: 0 5px 0 0;
  }
  .box {
    -webkit-box-shadow: 10px 9px 20px -14px rgba(0,0,0,0.75);
-moz-box-shadow: 10px 9px 20px -14px rgba(0,0,0,0.75);
box-shadow: 10px 9px 20px -14px rgba(0,0,0,0.75);
  }
  .title-view {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    h1 {
      padding-bottom: 0;
    }
    a {
      font-size: 13px;
      &::before {
        content: '< '
      }
    }
  }
  .steps {
    .step-item.is-active {
      .step-marker {
        border-color: var(--primary-color-dark);
        color: var(--primary-color-dark);
      }
    }
    .step-item.is-completed {
      .step-marker {
        color: #fff;
        background-color: var(--primary-color-dark);
      }
    }
    .step-item.is-active:before {
      background: linear-gradient(to left, #dbdbdb 0%, var(--primary-color-dark) 100%);
    }
    .step-item.is-completed:before {
      background: linear-gradient(to left, var(--primary-color-dark) 0%, var(--primary-color-dark) 100%);
    }
  }
</style>
