import api from '@/lib/api'

export default {
  async changeStatus ({ dispatch }, commitId) {
    try {
      const payload = await api.changeStatusAPI(commitId)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getBlogComments ({ dispatch }, { page = 1, size = 10 }) {
    try {
      const payload = await api.getBlogCommentsAPI(page, size)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getPostList ({ dispatch }, { page = 1, size = 10 }) {
    try {
      const payload = await api.getPostList(page, size)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getPostDetail ({ dispatch }, postId) {
    try {
      const payload = await api.getPostDetailAPI(postId)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async addPost ({ dispatch }, data) {
    try {
      const { success, message } = await api.addPostAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: message })

      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async editPost ({ dispatch }, { data, postId }) {
    try {
      const { success, message } = await api.editPostAPI(data, postId)
      dispatch('addTemporalNotification', { type: 'success', message: message })

      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async changePostStatus ({ dispatch }, postId) {
    try {
      const { success, message } = await api.changePostStatusAPI(postId)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getBlogCategories () {
    try {
      const payload = await api.getBlogCategoriesAPI()
      return payload
    } catch (error) {
      return false
    }
  },
  async getCategoriesList ({ dispatch }, { page = 1, size = 10 }) {
    try {
      const payload = await api.getCategoriesListAPI(page, size)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async addCategories ({ dispatch }, category) {
    try {
      const { success, message } = await api.addCategoriesAPI(category)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async changeStatusBlogCategory ({ dispatch }, postId) {
    try {
      const { success, message } = await api.changeStatusBlogCategoryAPI(postId)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async updateBlogCategory ({ dispatch }, data) {
    try {
      const { success, message } = await api.updateBlogCategoryAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
