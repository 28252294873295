import api from '@/lib/api'

export default {
  async getClaims ({ commit, state, dispatch }, { page, search }) {
    try {
      let payload = await api.getClaimsAPI(page, search)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: 'Ha ocurrido un error al obtener los reclamos.' })
      return false
    }
  },
  async getClaimsMessages ({ commit, state, dispatch }, { claimId, page }) {
    try {
      let payload = await api.getClaimsMessagesAPI(claimId, page)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: 'Ha ocurrido un error al obtener los mensajes.' })
      return false
    }
  },
  async responseClaims ({ commit, state, dispatch }, { claimId, message }) {
    try {
      let payload = await api.responseClaimsAPI(claimId, message)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: 'Ha ocurrido al guardar el mensajes.' })
      return false
    }
  },
  async closeClaims ({ commit, state, dispatch }, { claimId, favor, message }) {
    try {
      let payload = await api.closeClaimsAPI(claimId, { favor, message })
      dispatch('addTemporalNotification', { type: 'success', message: payload.message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: 'Ha ocurrido al guardar el mensajes.' })
      return false
    }
  },
  async csvDownloadClaims () {
    try {
      let { payload } = await api.csvDownloadClaims()
      return payload
    } catch (error) {
      return false
    }
  }
}
