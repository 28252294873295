export default {
  updateStepNewProduct (state, payload) {
    state.newProduct.step = payload
  },
  setNewProductValues (state, payload) {
    state.newProduct = { ...state.newProduct, ...payload }
  },
  clearStateNewProduct (state) {
    state.newProduct = {
      title: null,
      decription: null,
      price: null,
      condition: '-1',
      categoriesSelected: [],
      characteristicsSelected: [],
      addressNewLocation: null,
      addressSelected: [],
      otherMehod: false,
      personalDelivery: false,
      images: [],
      stock: null,
      box: null,
      accessories: null,
      placeDeposit: null,
      freeShipping: 0,
      model: 0,
      brand: 0,
      step: 1
    }
  }
}
