import api from '@/lib/api'

export default {
  async getDashboard ({ dispatch }) {
    try {
      let { payload } = await api.getDashboardAPI()
      return { ...payload }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getDashboardChartProducts ({ dispatch }) {
    try {
      let { payload } = await api.getDashboardChartProductsAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getDashboardSegmentation ({ dispatch }, date) {
    try {
      let { payload } = await api.getDashboardAPISegmentation(date)
      return { ...payload }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getDashboardChartProductsSegmentation ({ dispatch }, date) {
    try {
      let { payload } = await api.getDashboardChartProductsAPISegmentation(date)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
