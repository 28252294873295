import api from '@/lib/api'

export default {
  async getAdminNotifications ({ dispatch }, { page = 1, search }) {
    try {
      let { payload, totalPages } = await api.getAdminNotifications(page, search)
      return { payload, totalPages }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async openNotification ({ dispatch }, notificationId) {
    try {
      let { payload, totalPages } = await api.openNotification(notificationId)
      return { payload, totalPages }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
