import api from '@/lib/api'

export default {
  async getBankMovements ({ commit, state, dispatch }, { page, search }) {
    try {
      let payload = await api.getBankMovementsAPI(page, search)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: 'Ha ocurrido un error al enviar el mensaje.' })
      return false
    }
  },
  async getBankBalances ({ commit, state, dispatch }) {
    try {
      let payload = await api.getBankBalancesAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: 'Ha ocurrido un error al enviar el mensaje.' })
      return false
    }
  },
  async incrementeGivsBalance ({ commit, dispatch }, { amount, accountId }) {
    try {
      let { success, message = null } = await api.incrementeGivsBalanceAPI(amount, accountId)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: 'Ha ocurrido un error al enviar el mensaje.' })
      return false
    }
  },

  async csvDownloadBank () {
    try {
      let { payload } = await api.csvDownloadBankAPI()
      return payload
    } catch (error) {
      return false
    }
  }
}
