import api from '@/lib/api'

export default {
  async getProducts ({ commit, state, dispatch }, { page = 1, filter = 0, search = '', limit = 10, sortKey = 'price', sortOrder = 'desc', username = '', startDate = '', endDate = '', onlyGiver = false }) {
    try {
      let payload = await api.getProductsAPI(page, filter, search, limit, sortKey, sortOrder, username, startDate, endDate, onlyGiver)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getProductDetail ({ commit, state, dispatch }, { productId }) {
    try {
      let { success, payload, error = '' } = await api.getProductDetailAPI(productId)
      if (success) {
        return payload
      } else {
        dispatch('addTemporalNotification', { type: 'danger', message: error })
        return {}
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async editProduct ({ dispatch }, { productId, product }) {
    try {
      let { success, error = '' } = await api.editProductAPI(productId, product)
      if (success) {
        return success
      } else {
        dispatch('addTemporalNotification', { type: 'danger', message: error })
        return {}
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async deleteImageProduct ({ dispatch }, { productId, image }) {
    try {
      let { success, error = '' } = await api.deleteImageProductAPI(productId, image)
      if (success) {
        return success
      } else {
        dispatch('addTemporalNotification', { type: 'danger', message: error })
        return {}
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async uploadImageProduct ({ dispatch }, { productId, file, main }) {
    try {
      let { success, image, error = '' } = await api.uploadImageProductAPI(productId, file, main)
      if (success) {
        return { success, image }
      } else {
        dispatch('addTemporalNotification', { type: 'danger', message: error })
        return {}
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async changeStatusProduct ({ dispatch }, { productId, status }) {
    try {
      let { success, message = '', error = '' } = await api.changeStatusProductAPI(productId, { status })
      dispatch('addTemporalNotification', { type: success ? 'success' : 'danger', message: success ? message : error })
      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async responseCommentProduct ({ dispatch }, { idComment, userId, productId, response }) {
    try {
      let { success, message = '', error = '' } = await api.responseCommentProductAPI(idComment, userId, productId, response)
      console.log('API Response:', { success, message, error })

      if (success) {
        dispatch('addTemporalNotification', {
          type: 'success',
          message: message || 'Respuesta enviada con éxito!'
        })
      } else {
        dispatch('addTemporalNotification', {
          type: 'danger',
          message: error || 'No se pudo enviar la respuesta.'
        })
      }
      return success
    } catch (error) {
      dispatch('addTemporalNotification', {
        type: 'danger',
        message: error.message || 'Error al tratar de enviar la respuesta.'
      })
      return false
    }
  },
  async changeCoverProduct ({ dispatch }, { productId, data }) {
    try {
      let { success } = await api.changeCoverAPI(productId, data)
      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async deleteProductById ({ dispatch }, productId) {
    try {
      let payload = await api.deleteProductByIdAPI(productId)
      dispatch('addTemporalNotification', { type: 'success', message: payload.payload.message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async removeProductById ({ dispatch }, productId) {
    try {
      let payload = await api.removeProductByIdAPI(productId)
      dispatch('addTemporalNotification', { type: 'success', message: payload.message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async csvDownloadProducts ({ dispatch }, { filter = 0, search = '', startDate = '', endDate = '', onlyGiver = false }) {
    try {
      let { payload } = await api.csvDownloadProductsAPI(filter, search, startDate, endDate, onlyGiver)
      return payload
    } catch (error) {
      return false
    }
  },

  async updateProductVisibility (_, productId) {
    try {
      await api.updateProductVisibility(productId)
      return true
    } catch (error) {
      return false
    }
  },

  async updateProductSold (_, productId) {
    try {
      await api.updateProductSold(productId)
      return true
    } catch (error) {
      return false
    }
  },

  async getSaleProduct (_, productId) {
    try {
      let { payload } = await api.getSaleProductApi(productId)
      return payload
    } catch (error) {
      return false
    }
  },
  async getNotificationProduct ({ commit, state, dispatch }, { productId }) {
    try {
      let { success, payload, error = '' } = await api.getNotificationProductAPI(productId)
      if (success) {
        return payload
      } else {
        dispatch('addTemporalNotification', { type: 'danger', message: error })
        return {}
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getDiagnostic ({ commit, state, dispatch }, { productId }) {
    try {
      let { success, payload, error = '' } = await api.getDiagnosticAPI(productId)
      if (success) {
        return payload
      } else {
        dispatch('addTemporalNotification', { type: 'danger', message: error })
        return {}
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getReceiptImage ({ commit, state, dispatch }, { productId }) {
    try {
      let { success, payload, error = '' } = await api.getReceiptImageAPI(productId)
      if (success) {
        return payload
      } else {
        dispatch('addTemporalNotification', { type: 'danger', message: error })
        return {}
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getPropertySession ({ commit, state, dispatch }, { productId }) {
    try {
      let { success, payload, error = '' } = await api.getPropertySessionAPI(productId)
      if (success) {
        return payload
      } else {
        dispatch('addTemporalNotification', { type: 'danger', message: error })
        return {}
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getPaymentImage ({ commit, state, dispatch }, { productId }) {
    try {
      let { success, payload, error = '' } = await api.getPaymentImageAPI(productId)
      if (success) {
        return payload
      } else {
        dispatch('addTemporalNotification', { type: 'danger', message: error })
        return {}
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async updateProductPreapproved ({ dispatch }, { productId }) {
    try {
      let { success, message = '', error = '' } = await api.updateProductPreapprovedAPI(productId)
      if (success) {
        return message || error
      } else {
        dispatch('addTemporalNotification', { type: 'danger', message: error })
        return {}
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async updateAditionalInformation ({ dispatch }, { productId, body }) {
    try {
      let { success, message = '', error = '' } = await api.updateAditionalInformationAPI(productId, body)
      dispatch('addTemporalNotification', { type: success ? 'success' : 'danger', message: success ? message : error })
      if (success) {
        return message || error
      } else {
        dispatch('addTemporalNotification', { type: 'danger', message: error })
        return {}
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async updateDegree ({ dispatch }, { productId, degree }) {
    try {
      let { success, message = '', error = '' } = await api.updateDegreeAPI(productId, degree)
      dispatch('addTemporalNotification', { type: success ? 'success' : 'danger', message: success ? message : error })
      if (success) {
        return message || error
      } else {
        dispatch('addTemporalNotification', { type: 'danger', message: error })
        return {}
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async updateProductProposal ({ dispatch }, { productId, amount }) {
    try {
      let { success, message = '', error = '' } = await api.updateProductProposalAPI(productId, amount)
      dispatch('addTemporalNotification', { type: success ? 'success' : 'danger', message: success ? message : error })
      return message || error
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async changeStatusProductReceipt ({ dispatch }, { productId }) {
    try {
      let { success, message = '', error = '' } = await api.changeStatusProductReceiptAPI(productId)
      dispatch('addTemporalNotification', { type: success ? 'success' : 'danger', message: success ? message : error })
      return message
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async changeStatusPaymentProduct ({ dispatch }, { productId }) {
    try {
      let { success, message = '', error = '' } = await api.changeStatusPaymentProductAPI(productId)
      dispatch('addTemporalNotification', { type: success ? 'success' : 'danger', message: success ? message : error })
      return message || error
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return error
    }
  },
  async changeStatusFinalizeProduct ({ dispatch }, { productId }) {
    try {
      let { success, message = '', error = '' } = await api.changeStatusFinalizeProductAPI(productId)
      dispatch('addTemporalNotification', { type: success ? 'success' : 'danger', message: success ? message : error })
      return message
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async changeStatusProductPaymentImage ({ dispatch }, { productId, fileImage }) {
    try {
      let { success, message = '', error = '' } = await api.changeStatusProductPaymentImageAPI(productId, fileImage)
      dispatch('addTemporalNotification', { type: success ? 'success' : 'danger', message: success ? message : error })
      return message || error
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
