const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getUsergroupsAPI () {
    const response = await fetch(`${API_URL}/admin/user-groups`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async createUsergroupsAPI (name) {
    const response = await fetch(`${API_URL}/admin/user-groups`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      },
      body: JSON.stringify({ name })
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async addUserToGroupAPI (userId, groupId) {
    const response = await fetch(`${API_URL}/admin/user-groups/add-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      },
      body: JSON.stringify({ userId, groupId })
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  }

  // async updateCategoryAPI (name, parentId, categoryId) {
  //   const response = await fetch(`${API_URL}/admin/categories/${categoryId}`, {
  //     method: 'PUT',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': getToken()
  //     },
  //     body: JSON.stringify({ name, parentId })
  //   })

  //   let resp = await response.json()

  //   if (!response.ok) {
  //     this.isLoading = false
  //     if (response.status === 400) {
  //       throw new Error(resp.error)
  //     }
  //   }
  //   return resp
  // }
}
