import api from '@/lib/api'

export default {
  async getComments ({ dispatch }, { page = 1, search }) {
    try {
      let { payload, totalPages } = await api.getCommentsAPI(page, search)
      return { payload, totalPages }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async csvDownloadComments (_, { search }) {
    try {
      let { payload } = await api.csvDownloadCommentsAPI(search)
      return payload
    } catch (error) {
      return false
    }
  }
}
