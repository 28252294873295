import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { split } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'
import { InMemoryCache } from 'apollo-cache-inmemory'

Vue.use(VueApollo)

const AUTH_TOKEN = 'access-token'

const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4000/graphql'
export const filesRoot = process.env.VUE_APP_FILES_ROOT || httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'))

Vue.prototype.$filesRoot = filesRoot

// const defaultOptions = {
//   httpEndpoint,
//   wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
//   tokenName: AUTH_TOKEN,
//   persisting: false,
//   websocketsOnly: false,
//   ssr: false,
//   cache: new InMemoryCache()
// }
const authLink = setContext(async (req, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN)
  return {
    headers: {
      ...headers,
      authorization: token
    }
  }
})

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP
})

const wsLink = new WebSocketLink({
  uri: process.env.VUE_APP_GRAPHQL_WS,
  options: {
    reconnect: true
  }
})

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return (kind === 'OperationDefinition' && operation === 'subscription')
  },
  wsLink,
  authLink.concat(httpLink)
)
// Call this in the Vue app file
export function createProvider (options = {}) {
  // Create apollo client
  // const { apolloClient, wsClient } = createApolloClient({
  //   ...defaultOptions,
  //   ...options
  // })
  // apolloClient.wsClient = wsClient

  const apolloClient = new ApolloClient({
    link,
    tokenName: localStorage.getItem(AUTH_TOKEN),
    defaultOptions: {
      fetchPolicy: 'cache-and-network',
      query: {
        fetchPolicy: 'all',
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'cache-and-network'
      }
    },
    cache: new InMemoryCache(),
    connectToDevTools: true
  })

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      }
    },
    errorHandler (error) {
      // eslint-disable-next-line no-console
      console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    }
  })

  return apolloProvider
}

// Manually call this when user log in
export async function onLogin (apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout (apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}
