import api from '@/lib/api'

export default {
  async getUsergroups ({ dispatch }) {
    try {
      let { payload } = await api.getUsergroupsAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async createUsergroups ({ dispatch }, { name }) {
    try {
      let { success, message = null } = await api.createUsergroupsAPI(name)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async addUserToGroup ({ dispatch }, { userId, groupId }) {
    try {
      let { success, message = null } = await api.addUserToGroupAPI(userId, groupId)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }

  // async updateCategory ({ dispatch }, { id, name, parentId }) {
  //   try {
  //     let { success, message = null } = await api.updateCategoryAPI(name, parentId, id)
  //     dispatch('addTemporalNotification', { type: 'success', message: message })
  //     return success
  //   } catch (error) {
  //     dispatch('addTemporalNotification', { type: 'danger', message: error.message })
  //     return false
  //   }
  // }
}
