const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getsalesAPI (page = 1, search = '', filter = '') {
    const response = await fetch(`${API_URL}/admin/sales?page=${page}&search=${search}&filter=${filter}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async getSaleByIdAPI (saleId) {
    const response = await fetch(`${API_URL}/admin/sales/${saleId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    const data = await response.json()

    if (!response.ok) {
      throw new Error(data.error)
    }

    return data
  },

  async csvDownloadSalesAPI (search = '', filter = '') {
    const response = await fetch(`${API_URL}/admin/sales/getcsv?search=${search}&filter=${filter}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async updateSaleStatusAPI (saleId, newStatus) {
    const response = await fetch(`${API_URL}/admin/sales/${saleId}/label`, { // Agregar /label aquí
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      },
      body: JSON.stringify({ status: newStatus })
    })
    const data = await response.json()
    if (!response.ok) {
      throw new Error(data.error)
    }
    return data
  }
}
