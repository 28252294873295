const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getExecutiveReportAPI (startDate, endDate, filter, page = 1, limit = 10) {
    const response = await fetch(`${API_URL}/admin/reports?startDate=${startDate}&endDate=${endDate}&filter=${filter}&page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async csvDownloadReportsAPI (startDate, endDate, filter) {
    const response = await fetch(`${API_URL}/admin/reports/getcsv?startDate=${startDate}&endDate=${endDate}&filter=${filter}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    })

    let resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  }
}
