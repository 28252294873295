
import api from '@/lib/api'

export default {
  async getChatId ({ dispatch }, userId) {
    try {
      let payload = await api.getChatId(userId)
      return payload
    } catch (error) {
      // console.log(error)
      dispatch('addTemporalNotification', { type: 'danger', message: 'Ha ocurrido un error al obtener el chat.' })
      return false
    }
  }
}
