import api from '@/lib/api'

export default {
  async getAdminUsers ({ commit, state, dispatch }, { page = 1, size = 10 }) {
    try {
      let payload = await api.getAdminUsers(page, size)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async adminUpdate ({ commit, dispatch, state }, data) {
    try {
      let { message } = await api.adminUpdate(data)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return true
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async createUserAdmin ({ dispatch }, data) {
    try {
      const { success, message } = await api.createUserAdmin(data)
      if (success) {
        return success
      } else {
        dispatch('addTemporalNotification', { type: 'danger', message })
        return false
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async csvDownloadAdminUser (_) {
    try {
      let { payload } = await api.csvDownloadAdminUsersAPI()
      return payload
    } catch (error) {
      return false
    }
  }
}
