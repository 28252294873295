export default {
  notifications: [],
  activeMenus: [],
  activeoption: '',
  sidebar: true,
  loading: false,
  catalogs: {
    views: [
      {
        path: 'path',
        name: 'name',
        icon: 'icon'
      }
    ]
  }
}
