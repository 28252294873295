import api from '@/lib/api'

export default {
  async getCategoriesProduct ({ commit, state, dispatch }) {
    try {
      let { payload } = await api.getAllCategoriesAPI()
      if (payload.length) {
        payload = payload.reduce((accum, item) => {
          if (!item.parent_id) return accum
          let isExistInAccum = accum.find(category => category.parent_id === item.parent_id)
          if (isExistInAccum) return accum
          let getCategoriesToParent = payload.filter(category => category.parent_id === item.parent_id)
          accum.push({
            parent_id: item.parent_id,
            categoryFather: item.categoryFather,
            categoriesList: getCategoriesToParent
          })
          return accum
        }, [])
      }
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getCharacteristicsProduct ({ commit, state, dispatch }) {
    try {
      let { payload } = await api.getAllCharacteristicsAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getAddressAdminDefault ({ commit, state, dispatch }) {
    try {
      let { payload } = await api.getAddressAdminDefaultAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async addProduct ({ commit, state, dispatch }) {
    try {
      const {
        title,
        description,
        brand,
        model,
        box,
        accessories,
        freeShipping,
        placeDeposit,
        price,
        condition,
        categoriesSelected,
        characteristicsSelected,
        addressNewLocation,
        addressSelected,
        otherMehod,
        personalDelivery,
        images,
        stock
      } = state.newProduct

      let { success } = await api.addPruduct({
        title,
        description,
        brand,
        model,
        box,
        accessories,
        freeShipping,
        placeDeposit,
        price,
        condition,
        categoriesSelected,
        characteristicsSelected,
        addressNewLocation,
        addressSelected,
        otherMehod,
        personalDelivery,
        images,
        stock
      })
      dispatch('addTemporalNotification', { type: 'success', message: 'Producto añadido exitosamente.' })
      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
