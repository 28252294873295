import api from '@/lib/api'

export default {
  async getPromotions ({ dispatch }, { page = 1, search }) {
    try {
      let { payload, totalPages } = await api.getPromotionsAPI(page, search)
      return { payload, totalPages }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getGroupUsersList ({ dispatch }, _) {
    try {
      let { payload } = await api.getGroupUsersListAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async createdPromotion ({ dispatch }, data) {
    try {
      let payload = await api.createdPromotionAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: payload.message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async deletedPromotion ({ dispatch }, promotionId) {
    try {
      let payload = await api.deletedPromotionAPI(promotionId)
      dispatch('addTemporalNotification', { type: 'success', message: payload.message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async editedPrmo ({ dispatch }, { data, promotionId }) {
    try {
      let payload = await api.editedPrmoAPI(data, promotionId)
      dispatch('addTemporalNotification', { type: 'success', message: payload.message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async promoDetail ({ dispatch }, promotionId) {
    try {
      let payload = await api.promoDetailAPI(promotionId)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async createdReferral ({ dispatch }, data) {
    try {
      let payload = await api.createdReferralAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: payload.message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getAmountRefferal ({ dispatch }) {
    try {
      let payload = await api.getAmountRefferalAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
