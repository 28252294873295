import api from '@/lib/api'

export default {
  async sendMessage ({ commit, state, dispatch }, { productId, body }) {
    try {
      let { payload, message } = await api.senMessageToProductAPI(productId, body)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: 'Ha ocurrido un error al enviar el mensaje.' })
      return false
    }
  }
}
