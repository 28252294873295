import api from '@/lib/api'

export default {
  async getCustomers ({ commit, state, dispatch }, { page = 1, search, filterGroup, startDate, endDate }) {
    try {
      let { payload, totalPages, total } = await api.getCustomersAPI(page, search, filterGroup, startDate, endDate)
      return { payload, totalPages, total }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getLeads ({ dispatch }, { page = 1, search, filterGroup }) {
    try {
      let { payload, totalPages } = await api.getLeadsAPI(page, search)
      console.log(payload)
      return { payload, totalPages }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async updateLeadsAdminContacted ({ dispatch }, { leadId, isContacted }) {
    try {
      let { payload, message } = await api.updateLeadsAdminContacted(leadId, isContacted)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return { payload }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getCustomerById ({ commit, state, dispatch }, { customerId, productStatus }) {
    try {
      let { payload, success, message = '' } = await api.getCustomerByIdAPI(customerId, productStatus)
      if (success) {
        return payload
      } else {
        dispatch('addTemporalNotification', { type: 'danger', message: message })
        return false
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getCustomerProductsById ({ commit, state, dispatch }, { customerId, productStatus, page = 1 }) {
    try {
      let { payload, success, message = '' } = await api.getCustomerProductsByIdAPI(customerId, productStatus, page)
      if (success) {
        return payload
      } else {
        dispatch('addTemporalNotification', { type: 'danger', message: message })
        return false
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getCustomerPurchasedProductsById ({ commit, state, dispatch }, { customerId, page = 1 }) {
    try {
      let { payload, success, message = '' } = await api.getCustomerPurchasedProductsByIdAPI(customerId, page)
      if (success) {
        return payload
      } else {
        dispatch('addTemporalNotification', { type: 'danger', message: message })
        return false
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async userUpdateStatus ({ commit, dispatch, state }, data) {
    try {
      const { message } = await api.userUpdateStatus(data)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return true
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async addGivsToUser ({ commit, dispatch, state }, { userId, amount }) {
    try {
      const { success, message } = await api.addGivsToUserAPI({ userId, amount })
      const type = success ? 'success' : 'danger'
      dispatch('addTemporalNotification', { type: type, message: message })
      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async csvDownloadUsers ({ dispatch }, { search, filterGroup, startDate, endDate }) {
    try {
      let { payload } = await api.csvDownloadUsersAPI(search, filterGroup, startDate, endDate)
      return payload
    } catch (error) {
      return false
    }
  },
  async csvDownloadLeads ({ dispatch }, { search, filterGroup }) {
    try {
      let { payload } = await api.csvDownloadLeadsAPI(search, filterGroup)
      return payload
    } catch (error) {
      return false
    }
  },
  async forwardMail ({ dispatch }, userId) {
    try {
      const { message } = await api.forwardMailAPI(userId)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return true
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
