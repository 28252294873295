import auth from './auth'
import adminUsers from './adminUsers'
import address from './address'
import users from './users'
import products from './products'
import categories from './categories'
import sales from './sales'
import comments from './comments'
import promotions from './promotions'
import catalogs from './catalogs'
import transactions from './transactions'
import dashboard from './dashboard'
import characteristics from './characteristics'
import messages from './messages'
import bankMovements from './bankMovements'
import claims from './claims'
import userGroups from './userGroups'
import notifications from './notifications'
import reports from './reports'
import blog from './blog'
import press from './press'
import userNotifications from './userNotifications'

export default {
  ...auth,
  ...adminUsers,
  ...address,
  ...users,
  ...products,
  ...categories,
  ...sales,
  ...comments,
  ...promotions,
  ...catalogs,
  ...dashboard,
  ...transactions,
  ...characteristics,
  ...messages,
  ...bankMovements,
  ...claims,
  ...userGroups,
  ...notifications,
  ...reports,
  ...blog,
  ...press,
  ...userNotifications
}
