import api from '@/lib/api'

export default {
  async getTransactions ({ dispatch }, { page = 1, search, filter = '', limit = 10 }) {
    try {
      let { payload, totalPages, accums } = await api.getTransactionsAPI(page, search, filter, limit)
      return { payload, totalPages, accums }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async csvDownloadTransactions (_, { search, filter = '' }) {
    try {
      let { payload } = await api.csvDownloadTransactionsAPI(search, filter)
      return payload
    } catch (error) {
      return false
    }
  }
}
